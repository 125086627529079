import { useState, useEffect, ReactNode } from 'react'
import SectionHeader from '../section-header'
import {
    Container,
    CarouselHeader,
    CarouselNav,
    CarouselNavContainer,
    CarouselContent,
    SectionHeaderWithPrivacy,
    Item,
} from './style'
import { NavigateLeftIcon, NavigateRightIcon } from '../../icons/utility'
import { giphyLightCharcoal } from '@giphy/colors'

type Props = {
    maxBlocks?: number
    title?: string
    titleUrl?: string
    isPrivate?: boolean
    isVisible?: boolean
    children: any[]
    className?: string
    onDone?: () => void
    CustomHeader?: ReactNode
}

const Carousel = ({
    maxBlocks = 4,
    title = '',
    titleUrl,
    isPrivate = false,
    isVisible = true,
    children,
    className = '',
    CustomHeader,
    onDone,
}: Props) => {
    const [isNavigating, setIsNavigating] = useState<boolean>(true)
    const [startIndex, setStartIndex] = useState(0)

    const nextSlide = () => {
        setIsNavigating(true)
        const nextIndex = startIndex + maxBlocks
        setStartIndex(Math.min(nextIndex, children.length - 1))
    }

    const prevSlide = () => {
        setIsNavigating(true)
        const prevIndex = startIndex - maxBlocks
        setStartIndex(Math.max(prevIndex, 0))
    }
    const isDone = startIndex + maxBlocks >= children.length
    useEffect(() => {
        setTimeout(() => {
            setIsNavigating(false)
        }, 600)
        if (isDone) {
            onDone?.()
        }
    }, [startIndex])
    const HeaderLabel = CustomHeader || (
        <SectionHeaderWithPrivacy>
            {isPrivate && <i className={'ss-lock'} />}
            <SectionHeader label={title} url={titleUrl} />
        </SectionHeaderWithPrivacy>
    )
    return (
        <Container className={className}>
            <CarouselHeader>
                {HeaderLabel}
                <CarouselNavContainer>
                    <CarouselNav onClick={prevSlide} isDisabled={!startIndex}>
                        <NavigateLeftIcon color={!startIndex ? `${giphyLightCharcoal}` : undefined} />
                    </CarouselNav>
                    <CarouselNav onClick={nextSlide} isDisabled={isDone}>
                        <NavigateRightIcon color={isDone ? `${giphyLightCharcoal}` : undefined} />
                    </CarouselNav>
                </CarouselNavContainer>
            </CarouselHeader>
            <CarouselContent isNavigating={isNavigating} isVisible={isVisible}>
                {children.slice(startIndex, startIndex + maxBlocks).map((item, index) => (
                    <Item key={index}>{item}</Item>
                ))}
            </CarouselContent>
        </Container>
    )
}

export default Carousel
