'use client'
import Modal from '@/app/(site)/gifs/[id]/menu/modal'
import { Container, CopyWrapper, Footer, Gif, modalPadding } from '@/app/(site)/gifs/[id]/menu/modal-sc'
import ShareComponents from '@/app/(site)/gifs/[id]/menu/share-services'
import Button from '@/components/button'
import { FlashMessageContext } from '@/context/flash-message'
import { giphyIndigo } from '@giphy/colors'
import { IGif } from '@giphy/js-types'
import { getContentOfGif, getCreatorOfGif, getUrlFileType, setGADataLayer } from 'analytics'
import { ReactNode, useContext, useState } from 'react'
import { createPortal } from 'react-dom'
import { useWindowSize } from 'react-use'
import { TapAndHoldIcon } from 'ui/src/icons/utility'
import { ShareUrlRenditions } from 'utils/src/media/sharing'

const ShareMenu = ({
    gif,
    iconSize,
    children,
    className,
}: {
    iconSize: number
    gif: IGif
    children: ReactNode
    className?: string
}) => {
    const [showPortal, setShowPortal] = useState(false)
    const { showMessage } = useContext(FlashMessageContext)
    const { width } = useWindowSize()
    return (
        <>
            <div
                className={className}
                onClick={() => {
                    setShowPortal(true)
                    setGADataLayer({
                        event: 'share_open',
                        options: {
                            content: getContentOfGif(gif),
                            creator: getCreatorOfGif(gif),
                        },
                    })
                }}
            >
                {children}
            </div>
            {showPortal
                ? createPortal(
                      <Modal
                          onClose={() => {
                              setGADataLayer({
                                  event: 'share_close',
                                  options: {
                                      content: getContentOfGif(gif),
                                      creator: getCreatorOfGif(gif),
                                  },
                              })
                              setShowPortal(false)
                          }}
                      >
                          <Container className={className}>
                              <CopyWrapper>
                                  <Gif
                                      src={gif.images.original.url}
                                      alt={gif.title}
                                      width={width - modalPadding}
                                      onClick={() => {
                                          setGADataLayer({
                                              event: 'share_success',
                                              options: {
                                                  social: {
                                                      socialChannel: 'copy',
                                                  },
                                                  content: getContentOfGif(gif, {
                                                      rendition: 'original',
                                                      format: getUrlFileType(gif.url),
                                                  }),
                                                  creator: getCreatorOfGif(gif),
                                              },
                                          })
                                      }}
                                  />
                                  {gif.type !== 'video' && (
                                      <h4 className="flex align-middle">
                                          <TapAndHoldIcon
                                              width={iconSize}
                                              height={iconSize}
                                              style={{ animationIterationCount: 2 }}
                                              className="animate-bounce1 inline-block align-middle"
                                          />
                                          Tap and Hold to Copy {gif.is_sticker ? 'Sticker' : 'GIF'}
                                      </h4>
                                  )}
                              </CopyWrapper>
                              <Footer>
                                  {navigator.share && (
                                      <Button
                                          color={giphyIndigo}
                                          onClick={() => {
                                              if (navigator.share) {
                                                  navigator.share({
                                                      title: gif.title,
                                                      text: gif.title,
                                                      url: gif.images.original.url,
                                                  })
                                                  setGADataLayer({
                                                      event: 'share_success',
                                                      options: {
                                                          social: {
                                                              socialChannel: 'share',
                                                          },
                                                          content: getContentOfGif(gif, {
                                                              rendition: ShareUrlRenditions.Original,
                                                              format: gif.images.original.url,
                                                          }),
                                                          creator: getCreatorOfGif(gif),
                                                      },
                                                  })
                                              }
                                          }}
                                      >
                                          Share GIF
                                      </Button>
                                  )}
                                  {navigator.clipboard && (
                                      <Button
                                          className="bg-giphyDarkGrey"
                                          onClick={() => {
                                              navigator.clipboard.writeText(gif.images.original.url)
                                              showMessage({ message: 'Link copied to clipboard', type: 'success' })
                                              setGADataLayer({
                                                  event: 'share_success',
                                                  options: {
                                                      social: {
                                                          socialChannel: 'share',
                                                      },
                                                      content: getContentOfGif(gif, {
                                                          rendition: 'original',
                                                          format: gif.images.original.url,
                                                      }),
                                                      creator: getCreatorOfGif(gif),
                                                  },
                                              })
                                          }}
                                      >
                                          Copy {gif.type === 'video' ? 'Clip' : 'GIF'} Link
                                      </Button>
                                  )}
                                  <ShareComponents gif={gif} iconSize={iconSize} />
                              </Footer>
                          </Container>
                      </Modal>,
                      document.body,
                      gif.id as string
                  )
                : null}
        </>
    )
}
export default ShareMenu
