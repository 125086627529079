'use client'
import { SectionHeader } from '@/components/sections'
import { relativeGifClick } from '@/util/url'
import { GifsResult } from '@giphy/js-fetch-api'
import { IGif } from '@giphy/js-types'
import { Gif, GifOverlayProps, VideoOverlay } from '@giphy/react-components'
import PaginationCarousel from 'ui/src/components/carousel'
import { desktopContent } from 'ui/src/constants'
import useFetchData from 'ui/src/hooks/use-fetch-data'

type Props = {
    initialGifs: IGif[]
    label?: string
    fetchGifs: (offset: number) => Promise<GifsResult>
}
const desktopClipCount = 3
const padding = 8 // this isn't accurate
const clipWidth = desktopContent / desktopClipCount - padding

const InfinitePageCarousel = ({ initialGifs, label, fetchGifs }: Props) => {
    const { gifs, doFetch } = useFetchData<IGif>({
        initialData: initialGifs,
        fetchData: fetchGifs,
    })
    return (
        <PaginationCarousel
            maxBlocks={desktopClipCount}
            title="Related Clips"
            CustomHeader={<SectionHeader label={label || 'Related Clips'} />}
            className={`[&_img]:object-cover`}
            onDone={() => {
                doFetch()
            }}
        >
            {gifs.map((gif: IGif) => (
                <Gif
                    key={gif.id}
                    overlay={(props: GifOverlayProps) => (
                        <VideoOverlay {...props} width={clipWidth} className="[&_video]:object-cover" />
                    )}
                    gif={gif}
                    width={clipWidth}
                    height={140}
                    onGifClick={(gif, event) => {
                        event.stopPropagation()
                        event.preventDefault()
                        relativeGifClick(gif, event)
                    }}
                />
            ))}
        </PaginationCarousel>
    )
}
export default InfinitePageCarousel
