import styled, { keyframes, css } from 'styled-components'
import { giphyWhite, giphyRed, giphyDarkestGrey, giphyLightCharcoal } from '@giphy/colors'

const expand = keyframes`
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const animateIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const ExpandKeyframe = css`
    ${expand}
`

export const AnimateInKeyframe = css`
    ${animateIn}
`

export const Container = styled.div`
    margin-bottom: 40px;
`

export const CarouselHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
`

export const SectionHeaderWithPrivacy = styled.div`
    display: flex;
    align-items: center;
    i {
        color: ${giphyRed};
        font-size: 12px;
        height: 19px;
        margin-right: 5px;
    }
`

export const CarouselNavContainer = styled.div`
    height: 28px;
    display: flex;
    align-items: center;
`

export const CarouselNav = styled.div<{ isDisabled: boolean }>`
    width: 36px;
    height: 20px;
    background: ${giphyLightCharcoal};
    color: ${giphyWhite};
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 5px;
    svg {
        width: 100%;
        height: 11px;
    }
    ${({ isDisabled }) =>
        isDisabled &&
        css`
            pointer-events: none;
            background: ${giphyDarkestGrey};
            svg {
                color: ${giphyLightCharcoal};
            }
        `}
    &:active {
        opacity: 0.8;
    }
`

export const Item = styled.div``

export const CarouselContent = styled.div<{ isNavigating: boolean; isVisible: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${({ isVisible }) =>
        isVisible &&
        css`{
            ${Item} {
                transform: translateY(100%);
        }
        }`}
    ${({ isNavigating }) =>
        isNavigating &&
        Array.from({ length: 4 }).map(
            (_, i) => css`
                ${Item}:nth-of-type(4n + ${i + 1}) {
                    opacity: 0;
                    animation: ${AnimateInKeyframe} 0.5s linear;
                    animation-delay: calc(${i + 1} * 0.05s);
                    animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
                    animation-fill-mode: both;
                    img {
                        animation: ${ExpandKeyframe} 0.3s linear;
                    }
                }
            `
        )}
`
