'use client'
import { IChannel } from '@giphy/js-types'
import { useGA4 } from '../../util/analytics'

const GA4 = ({ channel }: { channel?: IChannel }) => {
    useGA4({ channel })
    return null
}

export default GA4
