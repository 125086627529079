'use client'
import Button from '@/components/button'
import { IGif, IImage } from '@giphy/js-types'
import { getContentOfGif, getCreatorOfGif, setGADataLayer } from 'analytics'
import { useContext, useState } from 'react'
import { LinkIcon } from 'ui/src/icons/utility'
import { ShareUrlRenditionType, ShareUrlRenditions } from 'utils/src/media/sharing'
import ShareComponents from '../share-services'
import { getShareUrl } from './download-panel'
import { GifPanelContext } from '../../../../../components/detail/gif-panel-context-manager'
import PanelHeader from './panel-header'
import { RenditionTray } from './rendition-tray'
import { FlashMessageContext } from '@/context/flash-message'
type Props = { gif: IGif }

const SharePanel = ({ gif }: Props) => {
    const { panel } = useContext(GifPanelContext)
    const { showMessage } = useContext(FlashMessageContext)
    const [currentRendition, setCurrentRendition] = useState<ShareUrlRenditionType>(ShareUrlRenditions.Social)
    const mediaType = gif.is_sticker ? 'Sticker' : gif.type === 'video' ? 'Clip' : 'Gif'
    return panel === 'share' ? (
        <div className="bg-giphyBlack absolute inset-0 flex flex-col bg-opacity-90">
            <PanelHeader title={`Share ${mediaType}`} />
            <div className="flex h-full flex-col items-center justify-center">
                <div className=" flex flex-col justify-center">
                    <div className=" mx-auto flex flex-1 flex-col justify-center gap-2">
                        <ShareComponents gif={gif} iconSize={24} />
                        {navigator.clipboard && (
                            <Button
                                onClick={() => {
                                    const url = getShareUrl((gif.images[currentRendition] as IImage).url)
                                    const imageUrl = url.replace('media.giphy.com', 'i.giphy.com')
                                    navigator.clipboard.writeText(imageUrl)
                                    setGADataLayer({
                                        event: 'share_success',
                                        options: {
                                            social: {
                                                socialChannel: 'share',
                                            },
                                            content: getContentOfGif(gif, {
                                                rendition: 'original',
                                                format: gif.images.original.url,
                                            }),
                                            creator: getCreatorOfGif(gif),
                                        },
                                    })
                                    showMessage({ message: 'Link copied to clipboard', type: 'success' })
                                }}
                                className="bottom-2 flex w-full items-center justify-center gap-2"
                            >
                                <LinkIcon width={16} /> <div>Copy {mediaType} Link</div>
                            </Button>
                        )}
                    </div>
                    <RenditionTray
                        currentRendition={currentRendition}
                        setRenditionType={setCurrentRendition}
                        gif={gif}
                        showsMp4
                    />
                </div>
            </div>
        </div>
    ) : null
}

export default SharePanel
